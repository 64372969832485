import React from "react"
import Content from "../Content"
import ContentContainer from "../ContentContainer"
import { StaticImage } from "gatsby-plugin-image"
import ButtonAction from "../ButtonAction"
import SocialButtons from "../SocialButtons"
import { Link } from "gatsby"

const Header = ({ isHome }) => {
  return (
    <ContentContainer className="absolute top-0 left-0 w-full">
      <Content className="flex flex-col lg:flex-row items-center flex-wrap pt-8 lg:pt-11 lg:pb-16 text-orange1 px-4 2xl:px-0">
        <div className="w-3/12 sm:w-1/4">
          <Link to="/">
            <div className="flex items-center justify-center">
              <StaticImage
                alt=""
                src="../../images/wantaw-logo-small.png"
                layout="constrained"
                placeholder="bl"
              />
            </div>
          </Link>
        </div>
        <div className="hidden lg:flex text-white  flex-grow flex-row-reverse pr-12 gap-x-16 ">
          <Link to="/#rental-rates" activeClassName="text-orange1">
            SURF RENTAL
          </Link>
          <Link to="/menu" activeClassName="text-orange1">
            MENU
          </Link>
          <Link to="/#about" activeClassName="text-orange1">
            ABOUT
          </Link>
        </div>
        <div
          className={`${
            isHome ? "hidden lg:flex" : "flex"
          } items-start lg:justify-end justify-center w-3/12 sm:w-1/4`}
        >
          <div className="flex flex-col gap-y-7 pt-5 lg:pt-0">
            <SocialButtons />
          </div>
        </div>
      </Content>
    </ContentContainer>
  )
}

export default Header
