import { Link } from "gatsby"
import React from "react"

const ButtonAction = ({ className, children, href, to }) => {
  return (
    <>
      {to ? (
        <Link to={to}>
          <div className={className}>{children}</div>
        </Link>
      ) : (
        <div className={className}>
          <a href={href} target="_blank" rel="noreferrer">
            {children}
          </a>
        </div>
      )}
    </>
  )
}

export default ButtonAction
