import React from "react"
import BackgroundImage from "gatsby-background-image"

const ContentContainer = ({ bgImage, className, children }) => {
  
  return bgImage ? (
    <BackgroundImage tag="" {...bgImage} className={className}>
      {children}
    </BackgroundImage>
  ) : (
    <div className={className}>{children}</div>
  )
}

export default ContentContainer
