import React from "react"
import { getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { convertToBgImage } from "gbimage-bridge"
import ContentContainer from "./ContentContainer"
import Content from "./Content"
import SocialButtons from "./SocialButtons"

const HeroBanner = ({ isHome }) => {
  const heroImage = useStaticQuery(graphql`
    {
      file: file(relativePath: { eq: "hero-image.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      small: file(relativePath: { eq: "hero-image-sm.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      subSmall: file(relativePath: { eq: "hero-image-sub-sm.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const image = convertToBgImage(getImage(heroImage.file))
  const smallimage = convertToBgImage(
    getImage(isHome ? heroImage.small : heroImage.subSmall)
  )

  const BannerContent = () => {
    return (
      <Content
        className={`${
          isHome ? "flex" : "hidden"
        } flex-col text-white pl-10 pr-32 2xl:pl-0 lg:pr-6`}
      >
        <div className="w-full lg:w-1/2 space-y-1 ">
          {isHome ? (
            <>
              <div className="inline-block">
                <h1 className="pb-3 lg:pb-0">
                  DINE <strong>SURF</strong> CHILL
                </h1>
                <hr className="border-orange1 border-t-1"></hr>
              </div>
              <p className="text-lg pt-3 lg:pt-0">
                Japanese restaurant, surfboard rentals, and chill-out area in
                Brgy. Pacifico, Siargao Island, Philippines.
              </p>
            </>
          ) : null}
        </div>
        <div className="flex lg:hidden w-1/3 mt-5">
          <SocialButtons />
        </div>
      </Content>
    )
  }

  return (
    <>
      <ContentContainer
        bgImage={image}
        className={`hidden lg:flex w-full bg-blend-darken bg-black bg-opacity-40 ${
          isHome ? "min-h-banner" : "min-h-bannerSub"
        }`}
      >
        <BannerContent />
      </ContentContainer>
      <ContentContainer
        bgImage={smallimage}
        className={`flex lg:hidden w-full bg-blend-darken bg-black bg-opacity-40 ${
          isHome ? "min-h-bannerSM" : "min-h-bannerSubSM"
        }`}
      >
        <BannerContent />
      </ContentContainer>
    </>
  )
}

export default HeroBanner
