import React from "react"

const Content = ({ children, className }) => {
  return (
    <div className="w-full flex flex-col items-center justify-center">
      {" "}
      <div className={`w-full max-w-content ${className}`}>{children}</div>
    </div>
  )
}

export default Content
