import React from "react"
import Content from "../Content"
import ContentContainer from "../ContentContainer"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

// FaFacebookSquare , FaInstagram
const Footer = () => {
  return (
    <ContentContainer className="bg-orange1">
      <Content className="flex flex-col items-center justify-center flex-wrap mt-11 mb-16 text-orange1">
        <div className="w-24 sm:w-full flex items-center justify-center">
          <Link to="/">
            <StaticImage
              alt=""
              src="../../images/wantaw-logo-small-2.png"
              layout="constrained"
              placeholder="blurred"
            />
          </Link>
        </div>
        <div className="mt-11 flex flex-row items-center justify-center gap-x-6 w-4/12 sm:w-1/4 ">
          <a
            href="https://www.facebook.com/wantawsiargao/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex items-center justify-center">
              <StaticImage
                alt=""
                src="../../images/fb.png"
                layout="constrained"
                placeholder="blurred"
              />
            </div>
          </a>
          <a
            href="https://www.instagram.com/wantawsiargao/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex items-center justify-center">
              <StaticImage
                alt=""
                src="../../images/insta.png"
                layout="constrained"
                placeholder="blurred"
              />
            </div>
          </a>
          <a
            href="https://goo.gl/maps/CiNRSKXZR34SV6wU6"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex items-center justify-center">
              <StaticImage
                alt=""
                src="../../images/google.png"
                layout="constrained"
                placeholder="blurred"
              />
            </div>
          </a>
        </div>
        <div className="mt-11 text-center">
          <p>© 2021 Wantaw</p>
          <p>Siargao Circumferential Rd., Pacifico, San Isidro,</p>
          <p>Surigao Del Norte, Philippines</p>
        </div>
      </Content>
    </ContentContainer>
  )
}

export default Footer
