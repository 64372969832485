import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const SocialButtons = () => {
  return (
    <div className="flex flex-row items-center justify-center gap-x-4">
      <div className="">
        <a
          href="https://www.facebook.com/wantawsiargao/"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            alt=""
            src="../images/fb-white.png"
            layout="constrained"
          />
        </a>
      </div>
      <div>
        <a
          href="https://www.instagram.com/wantawsiargao/"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            alt=""
            src="../images/insta-white.png"
            layout="constrained"
          />
        </a>
      </div>
      <div>
        <a
          href="https://goo.gl/maps/CiNRSKXZR34SV6wU6"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            alt=""
            src="../images/google-white.png"
            layout="constrained"
          />
        </a>
      </div>
    </div>
  )
}

export default SocialButtons
